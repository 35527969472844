import React from "react"
import Bandeau from "../components/bandeau"
import Image from "react-bootstrap/Image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

function getPicturePath(imgName) {
  const images = require.context("../images/", true)
  return images("./" + imgName)
}

const Apropos = () => (
  <Bandeau title="Passion Cartonnage & Encadrement" dark>
    <Container>
      <Row className="d-flex justify-content-center">
        <Image
          src={getPicturePath("nad.jpg")}
          roundedCircle
          width="200px"
          className="m-0"
        />
      </Row>
      <br></br>
      <br></br>
      <Row className="d-flex">
        <p className="justified">
          Un matin, une reconversion de cœur s’est imposée à moi. Après avoir
          travaillé pendant plusieurs années dans le tourisme et la formule F1,
          je décide de vivre de ma passion. Un défi, un challenge ou une envie
          irrésistible de faire ce qui m'anime profondément depuis si longtemps.
        </p>
        <p className="justified">
          Il est temps pour moi de mettre au grand jour cette puissance
          créatrice qui me fait tant vibrer. Depuis ma plus petite enfance, je
          suis attirée par les gens, le fil, la terre, les tissus…la matière a
          un vrai sens pour moi. J’aime toucher, sentir, créer avec tout et pas
          grand-chose. Je ressens un vrai plaisir à créer. Je rêve de faire les
          beaux-arts mais la vie me propose un autre chemin c’est ainsi que je
          suis agent de tourisme, pendant plusieurs années. Durant cette
          période, lors de mes loisirs, je travaille le fil, le tissu... Tout
          ceci me maintient dans un parfait équilibre travail / loisirs.
        </p>
        <p className="justified">
          ... puis un jour je découvre l’activité cartonnage.
        </p>
        <p className="justified">
          Je n’imaginais pas la suite, je n’imaginais pas comment des années
          plus tard j’allais être transportée vers une aventure extraordinaire.
          Dans les années 2000 je quitte la région Parisienne pour Le Sud-Ouest
          d’où je suis native. Je m’occupe de mes enfants, découvre une autre
          vie, fais des rencontres jusqu’au jour où une amie me propose de
          décorer sa boutique. C’était parti pour la grande aventure. Challenge
          relevé ; je suis amenée à donner des cours, puis j’ouvre mon atelier
          Hiva Hoa créations. Je transmets mon savoir-faire et je découvre la
          passion de transmettre ce savoir-faire. Quelle satisfaction de voir
          mes élèves heureuses et détendues. Peu de temps après et à leur
          demande, j'ouvre des cours d’encadrements. Je rencontre Corine Vorain,
          encadreuse à Villenave d’Ornon à <b>Atelier ID Cadres</b>, nous formons une
          véritable équipe. Malheureusement pour elle, elle est contrainte de
          fermer sa boutique mais elle me réservait une belle surprise
          puisqu’elle me confiait sa clientèle. Voilà comment aujourd’hui je
          suis pleinement heureuse dans mon atelier Hiva Hoa créations où je
          reçois mes élèves et mes clients. Je me suis laissée porter et guider
          par la vie. Créer ou transmettre ma passion et mon savoir faire est
          une vraie joie de vivre, je dirai même un art de vivre. Une révélation
          qui me procure de forts moments de partage et un grand bien être.
        </p>
        <p className="justified">
          Je remercie ma famille, mes amis, mes élèves et clients, mes
          partenaires de m’avoir accompagnée et de m’accompagner encore sur ce
          chemin.
        </p>
      </Row>
      <Row className="text-right">
        <Col md={6} className="p-0"></Col>
        <Col md={6} className="p-0">
          <span>Au plaisir de vous recevoir,</span>
          <br></br>
          <span>Nadine Comblat</span>
        </Col>
      </Row>
    </Container>
  </Bandeau>
)
export default Apropos
