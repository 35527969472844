import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Apropos from "../containers/apropos"

const AProposPage = () => (
  <Layout>
    <SEO title="A Propos" />
    <br/><br/>
    <Apropos />
  </Layout>

)
export default AProposPage
